
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.colored {
    text-align: left;
    li::marker {
        color: $primary-color;
    }
}
